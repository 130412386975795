
.rank-table{
  tbody{
    tr{
      background: #e1e3e6;
      color: #737373;
      /*display: none;*/
    }
    tr:not(.count-0):nth-child(1){
      background: #0271be;
      color: #fff;
    }
    tr:not(.count-0):nth-child(2){
      background: #bc1823;
      color: #fff;
    }
    tr:not(.count-0):nth-child(3){
      background: #fff;
    }
    tr.active{
      display: table-row;
    }
  }
}
